<template>
  <section>
    <div
      :class="{
        container: !pageWidth,
        'max-w-[1320px] m-auto p-6': pageWidth === 'shop',
      }"
    >
      <div class="lg:w-2/3">
        <slot name="breadcrumbs" />
      </div>
      <div class="my-6 lg:my-16 lg:flex lg:flex-nowrap">
        <div
          :class="[
            'mb-14 lg:mb-0 lg:order-2 lg:w-1/3 lg:ml-10',
            {
              'lg:-mt-60': sideTop === 'contact',
              'lg:-mt-88': sideTop === 'shop',
            },
          ]"
        >
          <slot name="side" />
        </div>
        <div class="lg:order-1 lg:w-2/3">
          <slot name="body" />
        </div>
      </div>
      <slot name="footer" />
    </div>
  </section>
</template>

<script>
export default {
  props: {
    sticky: {
      type: Boolean,
      default: true,
    },

    sideTop: {
      type: String,
      default: null,
    },

    pageWidth: {
      type: String,
      default: null,
    },
  },
}
</script>
