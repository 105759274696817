var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('div',{class:{
      container: !_vm.pageWidth,
      'max-w-[1320px] m-auto p-6': _vm.pageWidth === 'shop',
    }},[_c('div',{staticClass:"lg:w-2/3"},[_vm._t("breadcrumbs")],2),_vm._v(" "),_c('div',{staticClass:"my-6 lg:my-16 lg:flex lg:flex-nowrap"},[_c('div',{class:[
          'mb-14 lg:mb-0 lg:order-2 lg:w-1/3 lg:ml-10',
          {
            'lg:-mt-60': _vm.sideTop === 'contact',
            'lg:-mt-88': _vm.sideTop === 'shop',
          },
        ]},[_vm._t("side")],2),_vm._v(" "),_c('div',{staticClass:"lg:order-1 lg:w-2/3"},[_vm._t("body")],2)]),_vm._v(" "),_vm._t("footer")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }