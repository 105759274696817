<template>
  <div
    :class="[
      {
        'lg:sticky': sticky,
        'lg:top-44': top === 'nav',
        'lg:top-64': top === 'subnav',
      },
    ]"
  >
    <div
      :class="[
        'py-12 px-6 lg:px-12 border-1 border-gray-lighter bg-white rounded-t-md',
        {
          'rounded-b-md': !$slots.footer,
        },
      ]"
    >
      <slot />
    </div>
    <slot name="footer" />
  </div>
</template>

<script>
export default {
  props: {
    sticky: {
      type: Boolean,
      default: true,
    },

    top: {
      type: String,
      default: 'subnav',
    },
  },
}
</script>
