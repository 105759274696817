<template>
  <div class="ucrs-stripBanner" :class="[bgColor, rootClass]">
    <div
      class="ucrs-stripBanner__inner"
      :class="full ? 'container' : 'ucrs-stripBanner__inner--wrapped'"
    >
      <div :class="contentColor" class="ucrs-stripBanner__content">
        <SvgIcon
          aria-hidden="true"
          name="bank-accounts"
          class="max-w-12 max-h-12 mr-8"
        />
        <slot />
      </div>
      <OButton
        tag="a"
        :href="link"
        :class="btnClass"
        :variant="btnVariant"
        :name="btnName"
      >
        <SvgIcon
          v-if="btnIcon"
          :name="btnIcon"
          class="ucrs-icon--button ucrs-icon--button__left"
        />
        {{ btnText }}
      </OButton>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    full: {
      type: Boolean,
      default: true,
    },
    btnText: {
      type: String,
      default: 'Scopri di più',
    },
    btnVariant: {
      type: String,
      default: 'primary',
    },
    btnName: {
      type: String,
      default: '',
    },
    btnIcon: {
      type: String,
      default: '',
    },
    btnClass: {
      type: String,
      default: 'ucrs-button--full-to-md',
    },
    bgColor: {
      type: String,
      default: 'bg-white',
    },
    rootClass: {
      type: String,
      default: '',
    },
    contentColor: {
      type: String,
      default: 'text-black-light',
    },
    link: {
      type: String,
      default: '#',
    },
  },
}
</script>
<style lang="postcss">
.ucrs-stripBanner {
  @apply border-2 rounded-md;

  &__inner {
    @apply text-center py-8;

    &--wrapped {
      @apply px-6;
    }
    @screen md {
      @apply flex justify-between items-center py-6 text-left;

      &--wrapped {
        @apply pl-12 pr-4;
      }
    }
  }

  &__content {
    @apply mb-6 text-lg uppercase font-bold flex items-center;
    @screen md {
      @apply text-xl mb-0 mr-5;
    }
  }
}
</style>
