<template>
  <div>
    <h4
      v-if="title"
      v-dompurify-html="title"
      class="abstract font-bold text-black-light text-center mb-4"
    />
    <p
      v-if="description"
      v-dompurify-html="description"
      class="text-black-light text-center mb-12 lg:px-8"
    />
    <form>
      <OSteps
        ref="Steps"
        v-model="activeStep"
        :animated="true"
        :rounded="true"
        :has-navigation="true"
        label-position="left"
      >
        <ValidationObserver
          v-slot="{ errors }"
          ref="FirstStepObserver"
          :slim="true"
        >
          <OStepItem step="1" label="Dati immobile" :clickable="false">
            <DropdownWithValidation
              :value="category"
              :options="categories"
              name="category"
              label="Categoria*"
              placeholder="Categoria*"
              rules="required"
              :errors="errors"
              :field-classes="errors.category?.length ? 'mb-22' : 'mb-6'"
              @select="onSelectCategory($event)"
              @focus="onTealiumFormFirstFieldFocus"
            />
            <DropdownWithValidation
              :value="typology"
              :options="typologies"
              name="typology"
              label="Tipologia*"
              placeholder="Tipologia*"
              rules="required"
              :errors="errors"
              :field-classes="errors.typology?.length ? 'mb-22' : 'mb-6'"
              @select="onFormFieldEvent($event, 'typology')"
            />
            <DropdownWithValidation
              :value="subAdministrativeArea"
              :options="subAdministrativeAreas"
              name="subAdministrativeArea"
              label="Provincia*"
              placeholder="Provincia*"
              rules="required"
              :errors="errors"
              :field-classes="
                errors.subAdministrativeArea?.length ? 'mb-22' : 'mb-6'
              "
              @select="onSelectState($event)"
            />
            <DropdownWithValidation
              :value="city"
              :options="cities"
              name="city"
              label="Comune*"
              placeholder="Comune*"
              rules="required"
              :errors="errors"
              :field-classes="errors.city?.length ? 'mb-22' : 'mb-6'"
              @select="onFormFieldEvent($event, 'city')"
            />
            <p class="text-sm text-black-light">* Campi obbligatori</p>
          </OStepItem>
        </ValidationObserver>
        <ValidationObserver
          v-slot="{ errors }"
          ref="SecondStepObserver"
          :slim="true"
        >
          <OStepItem step="2" label="Dati utente" :clickable="false">
            <InputWithValidation
              :value="first_name"
              name="name"
              label="Nome*"
              placeholder="Nome*"
              rules="required|alpha_spaces"
              :errors="errors"
              :field-classes="errors.name?.length ? 'mb-22' : 'mb-6'"
              @input="onFormFieldEvent($event, 'first_name', 'name')"
            />
            <InputWithValidation
              :value="last_name"
              name="surname"
              label="Cognome*"
              placeholder="Cognome*"
              rules="required|alpha_spaces"
              :errors="errors"
              :field-classes="errors.surname?.length ? 'mb-22' : 'mb-6'"
              @input="onFormFieldEvent($event, 'last_name', 'surname')"
            />
            <InputWithValidation
              :value="email"
              name="email"
              label="Email*"
              placeholder="Email*"
              rules="required|email"
              :errors="errors"
              :field-classes="errors.email?.length ? 'mb-22' : 'mb-6'"
              @input="onFormFieldEvent($event, 'email')"
            />
            <InputWithValidation
              :value="phone"
              name="phone"
              label="Telefono*"
              type="number"
              placeholder="Telefono*"
              rules="required|phone"
              :errors="errors"
              :field-classes="errors.phone?.length ? 'mb-22' : 'mb-6'"
              @input="onFormFieldEvent($event, 'phone')"
            />
            <p class="text-sm text-black-light mb-6">* Campi obbligatori</p>
            <PrivacyCheckbox
              v-if="!$store.state.auth.loggedIn"
              v-model="privacy"
              name="privacy"
              modal-width="123rem"
              :errors="errors"
              :class="errors.privacy?.length ? 'mb-22' : 'mb-6'"
            />
            <UcrsSwitch
              v-if="!$store.state.auth.loggedIn"
              v-model="signup"
              name="signup"
              :true-value="1"
              :false-value="0"
              :left-label="true"
              >Registrati all'area riservata
            </UcrsSwitch>
          </OStepItem>
        </ValidationObserver>
        <div
          slot="navigation"
          slot-scope="{ previous, next }"
          class="mt-6 md:mt-12"
        >
          <OButton
            v-show="!next.disabled"
            variant="primary"
            root-class="ucrs-button--large w-full"
            @click.prevent="onNext(next)"
          >
            Continua
          </OButton>
          <div v-show="!previous.disabled">
            <OButton
              variant="primary"
              root-class="ucrs-button--large w-full"
              :disabled="isDisabled"
              @click.prevent="submit(previous)"
            >
              Invia
            </OButton>
          </div>
        </div>
      </OSteps>
    </form>
    <p class="mt-6 text-lg text-center">
      Numero verde
      <a class="text-blue font-bold underline" href="tel:800896968">
        800.89.69.68
      </a>
    </p>
    <p class="mt-2 text-lg text-center">
      Dall'estero
      <a class="text-blue font-bold underline" href="tel:+390284251701">
        +39 02 84251701
      </a>
    </p>
  </div>
</template>

<script>
// Mixins
import FormMixin from '~/mixins/form'

// Components
import DropdownWithValidation from '~/components/DropdownWithValidation.vue'
import InputWithValidation from '~/components/InputWithValidation.vue'
import PrivacyCheckbox from '~/components/PrivacyCheckbox.vue'
import UcrsSwitch from '~/components/UcrsSwitch.vue'

const LazyModalInner = () => import('~/components/ModalInner')
const LazyModalResponse = () => import('~/components/ModalResponse')

export default {
  components: {
    DropdownWithValidation,
    InputWithValidation,
    PrivacyCheckbox,
    UcrsSwitch,
  },

  mixins: [FormMixin],

  fetchOnServer: false,

  props: {
    title: {
      type: String,
      default: 'Valuta e vendi il tuo immobile',
    },

    description: {
      type: String,
      default:
        'Compila il form, ti metteremo in contatto con il nostro consulente di zona.',
    },

    cityName: {
      type: String,
      default: '',
    },

    embedded: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      activeStep: 1,
      secondStepClickable: false,
      categories: [],
      typologies: [],
      subAdministrativeAreas: [],
      cities: [],
      category: null,
      typology: null,
      subAdministrativeArea: null,
      city: null,
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      privacy: false,
      signup: 0,
      isDisabled: false,
    }
  },

  async fetch() {
    const catRes = await this.$axios.get('/_api/api/v1/categories', {
      params: {
        sell_categories: true,
      },
    })
    this.categories = catRes.data.data

    const statesRes = await this.$axios.get('/_api/api/v2/geo/states')
    this.subAdministrativeAreas = statesRes.data.data

    /* Populate if in vendo/_slug */
    if (this.cityName !== '') {
      this.subAdministrativeArea = this.subAdministrativeAreas.find(
        (it) => it.name === this.cityName
      )
      this.cities = await this.fetchCities(this.subAdministrativeArea.id)
      this.city = this.cities.find((it) => it.name === this.cityName)
    }

    /* Cookies set in Homepage */
    const stateId = this.$cookies.get('idProvinciaVendo')
    if (stateId && this.cityName === '') {
      this.subAdministrativeArea = this.subAdministrativeAreas.find(
        (it) => it.id === stateId
      )
      this.cities = await this.fetchCities(this.subAdministrativeArea.id)
      if (this.$cookies.get('idComuneVendo')) {
        this.city = this.cities.find(
          (it) => it.id === this.$cookies.get('idComuneVendo')
        )
      }
    }
  },

  watch: {
    activeStep(activeStep) {
      this.tealiumTrack(activeStep)
    },
  },

  mounted() {
    const { embedded, activeStep } = this

    if (!embedded) {
      this.tealiumTrack(activeStep)
    }

    if (this.$store.state.auth.loggedIn) {
      this.first_name = this.$store.state.auth.user.first_name
      this.last_name = this.$store.state.auth.user.last_name
      this.email = this.$store.state.auth.user.email
      this.phone = this.$store.state.auth.user.phone
      this.privacy = true
    }
  },

  methods: {
    tealiumTrack(activeStep) {
      let pageTrigger = null

      switch (activeStep) {
        case 1:
          pageTrigger = 'VENDO_DATI_IMMOBILE'
          break

        case 2:
          pageTrigger = 'VENDO_DATI_PERSONALI'
          break
      }

      if (!pageTrigger) {
        return
      }

      this.$tealium.pageTrack({ page_trigger: pageTrigger }, true)
    },

    async onSelectCategory(selected) {
      this.typologies = []
      this.typology = ''
      this.category = selected
      if (!this.category) return
      const res = await this.$axios.get('/_api/api/v1/typologies', {
        params: {
          category: this.category.id,
        },
      })
      this.typologies = res.data.data

      this.tealiumFormUpdateLastField('category')
    },

    async onSelectState(selected) {
      if (this.$fetchState.pending) {
        return
      }
      this.cities = []
      this.city = ''
      this.subAdministrativeArea = selected
      if (!this.subAdministrativeArea) return
      this.cities = await this.fetchCities(this.subAdministrativeArea.id)

      this.tealiumFormUpdateLastField('subAdministrativeArea')
    },

    async fetchCities(stateId) {
      const res = await this.$axios.get(`/_api/api/v2/geo/states/${stateId}`, {
        params: {
          embed: 'cities',
        },
      })
      return res.data.data.cities
    },

    async validate(observer) {
      await this.$refs[observer].validate()
      const fields = []
      if (this.$refs[observer].flags.invalid) {
        const errors = this.$refs[observer].errors
        for (const property in errors) {
          if (errors[property][0]) fields.push(property)
        }
      }
      return { isValid: this.$refs[observer].flags.valid, errorFields: fields }
    },

    async onNext(next) {
      const validation = await this.validate('FirstStepObserver')
      if (validation.isValid) {
        next.action()
      } else {
        const tealiumErrors = validation.errorFields.map((it) => {
          return { campo: it }
        })
        this.$tealium.track({
          event_name: 'errori_richiesta',
          errori_richiesta: tealiumErrors,
        })
      }
    },

    async submit(previous) {
      const validation = await this.validate('SecondStepObserver')
      if (!validation.isValid) {
        const tealiumErrors = validation.errorFields.map((it) => {
          return { campo: it }
        })
        this.$tealium.track({
          event_name: 'errori_richiesta',
          errori_richiesta: tealiumErrors,
        })
        return
      }

      this.isDisabled = true

      try {
        const res = await this.$axios.post(`/_api/api/v1/leads`, {
          first_name: this.first_name,
          last_name: this.last_name,
          email: this.email,
          phone: this.phone,
          privacy: this.privacy ? 1 : 0,
          type: 'sell',
          is_quick_registration: this.signup,
          ...(this.category ? { category: this.category.id } : {}),
          ...(this.typology ? { property_type: this.typology.id } : {}),
          ...(this.city ? { city: this.city.id } : {}),
          ...(this.subAdministrativeArea
            ? { sub_administrative_area: this.subAdministrativeArea.id }
            : {}),
        })

        const quickRegistration = res.data.data.quick_registration

        let content

        switch (true) {
          case !quickRegistration: // Manifestazione normale.
            content = `
              <p class="font-medium text-3xl mb-12 text-green">
                La manifestazione di interesse è stata inviata con successo.
              </p>
              <p class="text-xl mb-8 text-green">
                Nei prossimi giorni verrai richiamato da un nostro operatore da un numero con prefisso 02 o 045 - al seguente recapito <strong>${this.phone}</strong>.<br aria-hidden="true"/>
                Se non riusciamo a trovarti libero, non preoccuparti. Ti invieremo un SMS con i nostri contatti e potrai richiamarci gratuitamente quando sarà più comodo per te.
              </p>
            `
            break

          case quickRegistration &&
            quickRegistration.message === 'user-created':
            content = `
              <p class="font-medium text-3xl mb-12 text-green">
                Ci siamo quasi!
              </p>
              <p class="text-xl mb-8 text-green">
                Hai ricevuto la mail con cui potrai finalizzarti.<br aria-hidden="true" />
                Ti stiamo redirezionando alla pagina per l'impostazione della tua password.
              </p>
            `
            break

          case quickRegistration &&
            quickRegistration.message === 'user-exists' &&
            quickRegistration.finalized:
            content = `
              <p class="font-medium text-3xl mb-12 text-green">
                La manifestazione di interesse è stata inviata con successo.
              </p>
              <p class="text-xl mb-8 text-green">
                Nei prossimi giorni verrai richiamato da un nostro operatore da un numero con prefisso 02 o 045 - al seguente recapito: <strong>${this.phone}</strong>. Se non riusciamo a trovarti libero, non preoccuparti. Ti invieremo un SMS con i nostri contatti e potrai richiamarci gratuitamente quando sarà più comodo per te.
              </p>
              <p class="text-xl text-green">
                Con questo indirizzo email risulti già registrato alla nostra Area Riservata. <a href="/area-riservata/login" class="underline hover:no-underline">Clicca qui per accedere al tuo account.</a>
              </p>
            `
            break

          case quickRegistration &&
            quickRegistration.message === 'user-exists' &&
            !quickRegistration.finalized:
            content = `
              <p class="font-medium text-3xl mb-12 text-green">
                La manifestazione di interesse è stata inviata con successo.
              </p>
              <p class="text-xl mb-8 text-green">
                Nei prossimi giorni verrai richiamato da un nostro operatore da un numero con prefisso 02 o 045 - al seguente recapito: <strong>${this.phone}</strong>. Se non riusciamo a trovarti libero, non preoccuparti. Ti invieremo un SMS con i nostri contatti e potrai richiamarci gratuitamente quando sarà più comodo per te.
              </p>
              <p class="text-xl text-green">
                Il tuo account non è ancora finalizzato, controlla la tua email per confermare la registrazione.
              </p>
            `
            break

          case quickRegistration && quickRegistration.message === 'user-logged':
            content = `
              <p class="font-medium text-3xl mb-12 text-green">
                La manifestazione di interesse è stata inviata con successo.
              </p>
              <p class="text-xl mb-8 text-green">
                Nei prossimi giorni verrai richiamato da un nostro operatore da un numero con prefisso 02 o 045 - al seguente recapito: <strong>${this.phone}</strong>. Se non riusciamo a trovarti libero, non preoccuparti. Ti invieremo un SMS con i nostri contatti e potrai richiamarci gratuitamente quando sarà più comodo per te.
              </p>
              <p class="text-xl text-green">
                Attualmente risulti già loggato. <a href="/area-riservata/dashboard" class="underline hover:no-underline">Clicca qui visualizzare la tua dashboard.</a>
              </p>
            `
            break
        }

        this.showModal(content, 'white', 'check')
        this.reset(previous)

        if (quickRegistration && quickRegistration.message === 'user-created') {
          this.$cookies.set('auth._token.laravelSanctum', 'true', {
            domain: this.$config.cookieDomain,
            secure: this.$config.cookieSecure,
            path: '/',
          })
          await this.$auth.fetchUser()
          setTimeout(() => {
            location.href =
              '/area-riservata/registrazione-veloce/modifica-password'
          }, 2500)
        }

        this.leadTracking(
          res.data.data.lead_id,
          quickRegistration && quickRegistration.message === 'user-created'
        )
      } catch (error) {
        this.showModal(
          '<p class="font-medium text-3xl">Errore nell\'invio della manifestazione.</p>',
          'white',
          'danger'
        )
        this.isDisabled = false
      }
    },

    leadTracking(leadId, isQuickRegistration) {
      this.$tealium.track({
        event_name: 'conferma_richiesta',
        user_type: 'venditore',
        id_getrix: leadId,
        user_email: this.email,
      })
    },

    showModal(content, color, icon) {
      this.$oruga.modal.open({
        parent: this,
        width: '83rem',
        animation: 'ucrs-modal-',
        component: LazyModalInner,
        props: {
          bgColor: color,
          component: LazyModalResponse,
          componentProps: { icon, iconClasses: 'text-green', content },
        },
        onCancel: this.trackModalClose,
        events: {
          close: () => {
            this.trackModalClose()
          },
        },
      })
    },

    reset(previous) {
      this.typologies = []
      this.cities = []
      this.category = null
      this.typology = null
      this.subAdministrativeArea = null
      this.city = null
      if (!this.$store.state.auth.loggedIn) {
        this.first_name = ''
        this.last_name = ''
        this.email = ''
        this.phone = ''
        this.privacy = true
      }
      this.signup = 0
      this.isDisabled = false
      this.$refs.FirstStepObserver.reset()
      this.$refs.SecondStepObserver.reset()
      previous.action()
    },

    trackModalClose() {
      this.$tealium.track({
        event_name: 'chiusura_richiesta',
      })
    },
  },
}
</script>

<style lang="postcss">
@import '~/assets/css/components/field';
@import '~/assets/css/components/button';
@import '~/assets/css/components/input';
@import '~/assets/css/components/switch';
@import '~/assets/css/components/steps';
</style>
