var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    {
      'lg:sticky': _vm.sticky,
      'lg:top-44': _vm.top === 'nav',
      'lg:top-64': _vm.top === 'subnav',
    },
  ]},[_c('div',{class:[
      'py-12 px-6 lg:px-12 border-1 border-gray-lighter bg-white rounded-t-md',
      {
        'rounded-b-md': !_vm.$slots.footer,
      },
    ]},[_vm._t("default")],2),_vm._v(" "),_vm._t("footer")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }