<template>
  <OSwitch ref="Switch" v-bind="$attrs" v-on="$listeners">
    <slot />
  </OSwitch>
</template>

<script>
export default {
  data() {
    return {
      toggleRef: null,
    }
  },

  watch: {
    '$attrs.value'(value) {
      this.toggleRef.setAttribute('aria-checked', `${Boolean(value)}`)
    },
  },

  mounted() {
    const switchRef = this.$refs.Switch
    const inputRef = switchRef.$refs.input
    const labelRef = switchRef.$refs.label
    const toggleRef = labelRef.querySelector('span.ucrs-switch__check')
    const slotLabelRef = labelRef.querySelector('span.ucrs-switch__label')

    const id = `${this.$attrs.name}-switch-label`
    slotLabelRef.id = id

    inputRef.setAttribute('tabindex', -1)

    this.toggleRef = toggleRef
    toggleRef.setAttribute('tabindex', 0)
    toggleRef.setAttribute('role', 'switch')
    toggleRef.setAttribute('aria-labelledby', id)
    toggleRef.setAttribute('aria-checked', `${Boolean(this.$attrs.value)}`)

    function focusToggle() {
      toggleRef.focus()
    }

    inputRef.addEventListener('focus', focusToggle)
  },
}
</script>
